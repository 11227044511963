import React, { useState, useEffect } from 'react';
import uppyPromise from '../../config/uppyConfig';
import css from './FileData.module.css';

function getFileSize(bytes) {
  if (bytes === 0) {
    return '0B';
  }
  const k     = 1024;
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
  const i     = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(2))} ${sizes[i]}`;
}

Array.prototype.unique = function() {
  return [...new Set(this)].sort();
};

const FileData = ({ listingID, isDownloadable }) => {
  const [files, setFiles] = useState(null);

  useEffect(() => {
    const fetchFiles = async () => {
      const response     = await fetch(`/api/uploads/${listingID}/`);
      const data         = await response.json();
      const filteredData = data.map(item => ({
        ...item,
        fileList: item.fileList.filter(file => !file.includes('.DS_Store')),
      }));

      setFiles(filteredData);
    };

    fetchFiles();

    uppyPromise.then(uppy => uppy.on('complete', fetchFiles));
    return () => {
      uppyPromise.then(uppy => uppy.off('complete', fetchFiles));
    };
  }, [listingID]);

  const getPathTree = paths => {
    const topLevelFiles = paths.filter(item => !item.includes('/'));
    const topLevel      = paths.map(item => item.split('/')[0]).unique();

    return (
      <ul>
        {topLevel.map(item => (
          <li key={item} className={css.folder}>
            {!topLevelFiles.includes(item) ? (
              <details>
                <summary className={css.dropDownArrow}>{item}</summary>
                {getPathTree(
                  paths
                    .filter(path => path.startsWith(item + '/'))
                    .map(path => path.slice(item.length + 1)),
                )}
              </details>
            ) : (
              item
            )}
          </li>
        ))}
      </ul>
    );
  };

  const getListItem = file => {
    const filename = file.path.split('/').pop();
    return (
      <li key={file.path}>
        {file.fileList?.length > 1 ? (
          <details>
            <summary>
              {isDownloadable ? <a href={file.path}>{filename}</a> : filename}{' '}
              {getFileSize(file.size)}
            </summary>
            {getPathTree(file.fileList)}
          </details>
        ) : (
          isDownloadable ? <a href={file.path}>{filename}</a> : filename
        )}
      </li>
    );
  };

  return (
    <div>
      <ul>{files ? files.map(getListItem) : "Loading..."}</ul>
    </div>
  );
};

export default FileData;
