import { mimeTypesForSale } from './configListing';

export default (async () => {
  if (typeof window !== 'undefined') {
    const Uppy = (await import('@uppy/core')).Uppy;
    const XHRUpload = (await import('@uppy/xhr-upload')).default;

    return new Uppy({
      autoProceed: true,
      restrictions: {
        allowedFileTypes: mimeTypesForSale,
        maxFileSize: 2 * 1024 * 1024 * 1024, // 2GB
        maxNumberOfFiles: 1,
      },
    }).use(XHRUpload, {
      formData: true,
    });
  } else {
    return null;
  }
})();
