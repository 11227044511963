import React from 'react';
import FileData from '../../components/FileData/FileData';
import { Heading } from '../../components';
import css from './ListingPage.module.css';

const SectionDownloadFile = ({ listingId, isDownloadable }) => {
  console.log(listingId, 'listingId-sectionDownloadFile');

  return (
    <div>
      <Heading as="h2" rootClassName={css.sectionHeading} id='files'>
        {' '}
        Files{' '}
      </Heading>
      <FileData listingID={listingId} isDownloadable={isDownloadable} />
    </div>
  );
};

export default SectionDownloadFile;
